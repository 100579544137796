import '../styles/TimePeriodItem.css'
import '@ig-phoenix/fonts'
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TooltipItem } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from '@ig-caa/i18n'
import { PercentBgColours, TimePeriodItemProps } from './AppProps'
import React from 'react'
import styled, { useTheme } from 'styled-components'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function TimePeriodItem (props: TimePeriodItemProps): JSX.Element {
  const { timePeriodData } = props
  const theme = useTheme()

  const { t } = useTranslation()
  const longPercent: number[][] = timePeriodData.hasTrades ? [[0, timePeriodData.longPercent]] : [[0, 100]]
  const shortPercent: number[][] = timePeriodData.hasTrades ? [[0, timePeriodData.shortPercent]] : [[0, 100]]

  const redTransparent = theme.color.text.short.value + '4D'
  const blueTransparent = theme.color.text.long.value + '4D'

  const getPercentBgColours = (): PercentBgColours => {
    if (!timePeriodData.hasTrades) {
      return {
        longPercentBgColour: theme.color.background.noTradingActivity.value,
        shortPercentBgColour: theme.color.background.noTradingActivity.value
      }
    }
    if (timePeriodData.longPercent >= timePeriodData.shortPercent) {
      return {
        longPercentBgColour: theme.color.text.long.value,
        shortPercentBgColour: redTransparent
      }
    }
    return {
      longPercentBgColour: blueTransparent,
      shortPercentBgColour: theme.color.text.short.value
    }
  }

  const buySellLabel = (): JSX.Element => {
    if (!timePeriodData.hasTrades) return <NoTradeLabel>{t('no-trading-activity')}</NoTradeLabel>
    if (timePeriodData.longPercent >= timePeriodData.shortPercent) return <BuyLabel>{timePeriodData.longPercent}% {t('buys')}</BuyLabel>
    return <SellLabel>{timePeriodData.shortPercent}% {t('sells')}</SellLabel>
  }

  const { longPercentBgColour, shortPercentBgColour } = getPercentBgColours()

  /* istanbul ignore next */
  const barOptions = {
    indexAxis: 'y' as const,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          color: 'transparent',
          borderColor: 'transparent',
          tickColor: 'transparent'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        filter: function () {
          return timePeriodData.hasTrades
        },
        padding: 5,
        callbacks: {
          label: function (context: TooltipItem<'bar'>) {
            return `${context.label}: ${context.parsed.x}%`
          }
        }
      }
    }
  }

  const barData = {
    labels: [''],
    datasets: [{
      label: t('sells'),
      data: shortPercent,
      backgroundColor: shortPercentBgColour,
      barThickness: 4
    }, {
      label: t('buys'),
      data: longPercent,
      backgroundColor: longPercentBgColour,
      barThickness: 4
    }]
  }

  return (
    <>
      <div className="dominantDirection">
        {t(timePeriodData.label)}
        {buySellLabel()}
      </div>
      <div className="bar-container">
        <Bar data-testid="bar" options={barOptions} data={barData} height="24" width="256"/>
      </div>
    </>
  )
}

const NoTradeLabel = styled.span`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: ${({ theme }) => theme.size.caption.small.text.value};
  margin-left: 5px;
`

const BuyLabel = styled.span`
  color: ${({ theme }) => theme.color.text.long.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  margin-left: 5px;
`

const SellLabel = styled.span`
  color: ${({ theme }) => theme.color.text.short.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  margin-left: 5px;
`
