import { useTheme } from 'styled-components'
import React from 'react'

export default function NoClientSentiment (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.disabled.value
  return (
    <svg width="56" height="56" viewBox="0 0 57 56" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M56 28.9914C55.4734 43.997 43.1428 56 28.0088 56C12.54 56 0 43.4601 0 27.9912C0 12.8572 12.003 0.526691 27.0085 0V11.2153C18.1929 11.7327 11.2035 19.0458 11.2035 27.9912C11.2035 37.2725 18.7275 44.7965 28.0088 44.7965C36.9543 44.7965 44.2673 37.8071 44.7848 28.9914H56ZM56.0175 28.0503C56.0175 28.0306 56.0175 28.0109 56.0175 27.9912C56.0175 27.9715 56.0175 27.9518 56.0175 27.9321V28.0503ZM56 26.9908H44.7847C44.2866 18.5062 37.4937 11.7134 29.0092 11.2153V5.22229e-06C43.6826 0.515116 55.4848 12.3174 56 26.9908Z" fill="#B5BDBE"/>
    </svg>
  )
}