import {
  ClientResponse,
  ClientResponseWithLabel,
  TimePeriodTypeWithLabel
} from '../components/AppProps'

export default function addTradingActivityLabel (data: ClientResponse): ClientResponseWithLabel {
  const { clientsPositions, tradingActivity } = data
  const tradingActivityData: TimePeriodTypeWithLabel[] = []

  tradingActivity.forEach((element) => {
    const label: string = {
      L1H: 'last-hour',
      L24H: 'today',
      L7D: 'this-week',
      L1M: 'this-month'
    }[element.timePeriod] ?? ''

    if (label !== '') tradingActivityData.push({ ...element, label })
  })

  return {
    clientsPositions,
    tradingActivity: tradingActivityData
  }
}
