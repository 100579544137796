import addTradingActivityLabel from '../utils/addTradingActivityLabel'
import { ENVIRONMENTS, ClientResponseWithLabel } from '../components/AppProps'

export const ENDPOINTS = (marketId: string) => ({
  prod: `https://api.ig.com/fundamentals-gateway/sentiment/client-sentiment/${marketId}`,
  demo: `https://demo-api.ig.com/fundamentals-gateway/sentiment/client-sentiment/${marketId}`,
  uat: `https://web-api.ig.com/fundamentals-gateway/sentiment/client-sentiment/${marketId}`,
  test: `https://net-api.ig.com/fundamentals-gateway/sentiment/client-sentiment/${marketId}`,
  local: `https://net-api.ig.com/fundamentals-gateway/sentiment/client-sentiment/${marketId}`
})

export default async function fetchSentiment (env: keyof typeof ENVIRONMENTS, marketId: string, xst: string): Promise<ClientResponseWithLabel> {
  if (marketId === '') {
    throw new Error('missing marketId')
  }
  const response: Response = await fetch(`${ENDPOINTS(marketId)[env]}`, { headers: { 'X-Security-Token': xst } })
  if (!response.ok) {
    throw new Error(`${response.status}`)
  }

  const responseData = await response.json()
  const modifiedData: ClientResponseWithLabel = addTradingActivityLabel(responseData)

  if (modifiedData.clientsPositions.total === 0 &&
    modifiedData.tradingActivity.every((timePeriod) => !timePeriod.hasTrades)) {
    throw new Error('missing client sentiment data')
  }
  return modifiedData
}
