import React from 'react'
import TimePeriodItem from './TimePeriodItem'
import '../styles/TradingActivity.css'
import '@ig-phoenix/fonts'
import { useTranslation } from '@ig-caa/i18n'
import { TradingActivityProps } from './AppProps'
import styled from 'styled-components'

export default function TradingActivity (props: TradingActivityProps): JSX.Element {
  const { t } = useTranslation()
  const { tradingActivity } = props

  const missingEveryTradingActivities = tradingActivity.every((timePeriod) => !timePeriod.hasTrades)

  const tradingActivityContent = (): JSX.Element => {
    if (missingEveryTradingActivities) {
      return <NoTradingMessage data-testid='no-trading-activity'>{t('missing-trading-activity')}</NoTradingMessage>
    }
    return <>
      { tradingActivity.map((timePeriodData, idx) =>
        <div key={idx}>
          <TimePeriodItem timePeriodData={timePeriodData} />
        </div>)
      }
    </>
  }
  return (
    <div className='trading-activity' data-testid='trading-activity'>
      <AppHeader>
        {t('trading-activity')}
      </AppHeader>
      { tradingActivityContent() }
    </div>
  )
}

const AppHeader = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  margin: 0 16px 17px;
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  letter-spacing: 2px;
`
const NoTradingMessage = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  margin-left: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
`
