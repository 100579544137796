import React from 'react'
import { Spinner } from 'ig-phoenix'
import OpenPositions from './OpenPositions'
import TradingActivity from './TradingActivity'
import ErrorPage from './ErrorPage'
import '../styles/ClientSentiment.css'
import { ClientResponseWithLabel, ClientSentimentProps } from './AppProps'
import fetchSentiment from '../utils/fetchSentiment'
import { useQuery } from '@tanstack/react-query'

export default function ClientSentiment (props: ClientSentimentProps): JSX.Element {
  const { marketId, env, xst } = props

  const { isLoading, isError, data } = useQuery<ClientResponseWithLabel, any>({
    queryKey: ['sentiment', marketId],
    queryFn: async () => await fetchSentiment(env, marketId, xst),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return <div className="client-sentiment-loading" data-testid='isLoading'><Spinner radius='40px' /></div>
  }

  if (isError || !marketId || !data) {
    return <ErrorPage />
  }

  return (
    <>
      <OpenPositions clientsPositions={data.clientsPositions}/>
      <TradingActivity tradingActivity={data.tradingActivity}/>
    </>
  )
}
