import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import ClientSentiment from './ClientSentiment'
import '../styles/App.css'
import styled from 'styled-components'
import { AppProps } from './AppProps'
import { Providers } from '@ig-caa/mfe-infrastructure'

export function App (props: AppProps): JSX.Element {
  const { theme, env, marketId, xst, sessionData: { clientLocale } } = props
  const localeLoader = async (locale: string) => await import(`../locales/${locale}.json`)
  const supportedLanguages = ['en-GB', 'en-US', 'de-DE', 'es-ES', 'fr-FR', 'it-IT', 'ja-JP', 'nl-NL', 'no-NO', 'pt-PT', 'sv-SE', 'zh-CN', 'zh-TW']

  return (
    <Providers
      appName='client-sentiment'
      clientLocale={clientLocale}
      localeLoader={localeLoader}
      supportedLanguages={supportedLanguages}
      theme={theme}
    >
      <AppContainer className={`app-caa-client-sentiment ${theme}`} data-testid='app-caa-client-sentiment'>
        <ClientSentiment marketId={marketId} env={env} xst={xst}/>
      </AppContainer>
    </Providers>
  )
}

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.primarySurface.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`

const lifecycles = singleSpaReact({ React, ReactDOM, rootComponent: App })
export const { bootstrap, mount, unmount, update } = lifecycles
export default lifecycles
