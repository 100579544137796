import '../styles/OpenPositions.css'
import { Chart, ArcElement, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Tooltip, Informative } from 'ig-phoenix'
import { useTranslation } from '@ig-caa/i18n'
import { OpenPositionsProps } from './AppProps'
import { Icon } from './ErrorPage'
import { NoClientSentiment } from '@ig-caa/media'
import React from 'react'
import styled, { useTheme } from 'styled-components'

Chart.register(ArcElement, Legend)

export default function OpenPositions (props: OpenPositionsProps): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()

  const { clientsPositions } = props

  const doughnutHeader = (position: 'long' | 'short'): JSX.Element => (
    <div className="rightData">
      <PositionPercent position={position} data-testid={`big-percent-stat-${position}`}>{clientsPositions[`${position}Percent`]}%</PositionPercent>
      <span className="shortOrLong">{t(position).toUpperCase()}</span>
    </div>
  )

  const tooltip = (position: 'long' | 'short'): JSX.Element => (
    <div className='client-percentage-statement'>
      <span className={`${position} statement`}>
        <Bullet position={position}>&bull;</Bullet>
        <Percentage position={position}>{clientsPositions[`${position}Percent`]}%</Percentage>
        <span>
          {t(`percentage-${position}-positions`)}
          <Tooltip
            position='bottom'
            zIndex='9'
            top='25px'
            width='250px'
            tipPosition='-136px'
            tooltipRender={() =>
              <Tooltip.Body>
                <span className='tooltip-body-text' data-testid={`tooltip-body-${position}`}>
                  {t(`${position}-position-hover-info`)}
                  <br/>
                  <br/>
                  {t('hover-info-disclaimer')}
                </span>
              </Tooltip.Body>
            }
          >
            <span className='tooltip-icon' data-testid={`tooltip-icon-${position}`}><Informative /></span>
          </Tooltip>
        </span>

      </span>
    </div>
  )

  const totalNumberOfPositions = (total: number): string => {
    if (total <= 50) return '0-50'
    else if (total <= 250) return '51-250'
    else if (total <= 500) return '251-500'
    else return '501+'
  }

  /* istanbul ignore next */
  const doughnutOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    cutout: 15
  }

  const doughnutData = {
    labels: [t('long'), t('short')],
    datasets: [{
      data: [clientsPositions.longPercent, clientsPositions.shortPercent],
      backgroundColor: [theme.color.text.long.value, theme.color.text.short.value],
      borderColor: theme.color.background.primarySurface.value
    }]
  }

  return (
    <div className="clientsPositions" data-testid="open-positions" >
      { clientsPositions.total > 0 &&
        <>
          <div className="doughnutContainer">
            <div className="leftData">
              <Doughnut data-testid="doughnut-chart" data={doughnutData} options={doughnutOptions} />
            </div>
            {clientsPositions.longPercent > clientsPositions.shortPercent ? doughnutHeader('long') : doughnutHeader('short')}
          </div>
          {tooltip('long')}
          {tooltip('short')}
          <div className="total-open">
            <TotalOpenStat data-testid="total-stat">{totalNumberOfPositions(clientsPositions.total)}</TotalOpenStat>
            <span className="total-open-description">{t('open-positions-info')}</span>
          </div>
        </>
      }
      { clientsPositions.total === 0 &&
        <>
          <NoActivePosition data-testid="no-active-position" className='no-active-position-message'>
            <Icon><NoClientSentiment /></Icon>
            <div className='error-message'>{t('missing-client-positions')}</div>
          </NoActivePosition>
        </>
      }
    </div>
  )
}

const NoActivePosition = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value}
`

const TotalOpenStat = styled.span`
  color: ${({ theme }) => theme.color.text.confirmation.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  margin-right: 2px;
  flex-shrink: 0;
`

const Bullet = styled.span<{
  position: string
}>`
  color: ${({ position, theme }) => position === 'short' ? theme.color.text.short.value : theme.color.text.long.value};
  line-height: ${({ theme }) => theme.size.list.lineHeight.value};
  margin-right: 6px;
  font-size: 20px;
`

const Percentage = styled.span<{
  position: string
}>`
  color: ${({ position, theme }) => position === 'short' ? theme.color.text.short.value : theme.color.text.long.value};
  font-weight: ${({ theme }) => theme.font.heading.h4.weight.value};
  margin-right: 2px;
  flex-shrink: 0;
`

const PositionPercent = styled.span<{
  position: string
}>`
  color: ${({ position, theme }) => position === 'short' ? theme.color.text.short.value : theme.color.text.long.value};
  font-size: ${({ theme }) => theme.size.heading.h1.text.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  line-height: ${({ theme }) => theme.size.heading.h1.lineHeight.value};
`
