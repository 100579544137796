import React from 'react'
import { useTranslation } from '@ig-caa/i18n'
import styled from 'styled-components'
import { NoClientSentiment } from '@ig-caa/media'

export default function ErrorPage (): JSX.Element {
  const { t } = useTranslation()

  return (
    <ErrorContainer data-testid='error-no-data'>
      <Icon><NoClientSentiment /></Icon>
      <Message className='error-message'>{t('no-trading-activity')}</Message>
    </ErrorContainer>
  )
}

const ErrorContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 56px / 2);
  left: calc(50% - 100px);
  min-width: 200px;
`

export const Icon = styled.div`
  height: 56px;
  width: 56px;
  flex-shrink: 0;
  margin-right: 8px;
`

const Message = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.text.tertiary.value};
`
